.projectSidbar {
  max-height: 500px;
  overflow-y: scroll;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--border);
}

::-webkit-scrollbar {
  width: 3px;
  background-color: var(--border);
}

::-webkit-scrollbar-thumb {
  background-color: var(--white);
}

.respeDetails {
  background-color: #011221;
  border: 1px solid var(--border) !important;
}

.repoTitle {
  font-size: 16px;
  padding: 10px 20px;
}
.projectRow {
  gap: 2%;
  row-gap: 10px;
}
.projectRow .colProject {
  width: 32%;
}

.projectRow .card-body {
  background: #010c15;
  background: var(--dark);
  color: #607b96;
  color: var(--text);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #343a40;
}

ul.TabTitle {
  list-style: none;
  padding: 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.tabsCategory {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.MyTabs {
  display: flex;
  gap: 5px;
  align-items: center;
}
input[type="checkbox"] {
  border: 2px solid #607b96 !important;
  border-radius: 2px;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 16px;
  width: 16px;
  -webkit-appearance: none;
  background-color: transparent;
}

input[type="checkbox"]:hover {
  opacity: 1;

  position: relative;
}

input[type="checkbox"]:checked {
  position: relative;

  background-color: #607b96;
  opacity: 1 !important;
}

input[type="checkbox"]:checked:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.catName {
  width: fit-content;
  display: flex;
  gap: 20px;
}

.categoryProjectList.p-3 {
  max-height: 500px !important;
  overflow-y: scroll;
}

.projectTitle {
  font-size: 18px;
}
