.navbar-brand {
  width: 250px;
  font-size: 16px;
  font-weight: 450;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--text);
}

.main_header {
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 999999 !important;
  width: 100%;
  position: fixed;
  top: 0 !important;
  border-bottom: 1px solid var(--border);
  left: 0;
  right: 0;

  nav {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    padding: 0;
    height: 45px;
  }
}

.navbar-nav {
  .nav-item {
    a {
      border-left: 1px solid var(--border);

      padding: 10px 30px !important;
      text-align: center;
      color: var(--text);
    }
    &:last-child a {
      border-right: 1px solid var(--border);
    }
  }
}
