.subTitle {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  color: #e5e9f0;
}

.pronounce {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  *:first-child {
    align-self: flex-end;
  }
  .icon-wrapper {
    display: inline-block;
    position: relative;
    z-index: 100;
    cursor: pointer;
  }
  .Title {
    font-size: 62px;
    font-weight: 400;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--white);
    margin-bottom: 0;
  }
}

.speaker:active {
  height: 0.8em;
  padding: 3.1px;
}

.subTitleBottom {
  font-size: 32px;
  font-weight: 300;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--orange);
}

.codeHero pre {
  background: transparent !important;
  margin: 0;
  line-height: 20px;
  padding: 3px 0px !important;
}
